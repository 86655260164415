
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserService } from '@/services/userService';
import UserAvatar from '@/components/beautified-containers/userAvatar.vue';
import { useCompanyService } from '@/services/companyService';
import { CompanyModel } from '@/models/companyModel';

export default defineComponent({
  name: 'NavBar',
  components: {
    UserAvatar
  },
  props: {
    pageTitle: {
      type: String,
      default: () => ''
    },
    hasMenu: {
      type: Boolean,
      default: () => true
    },
    pageTitleLink: {
      type: String,
      default: () => ''
    },
    params: {
      type: Object,
      default: () => ({})
    },
    titleIsCentered: {
      type: Boolean,
      default: () => false
    }
  },
  setup() {
    const navbarCollapsed = ref(true);
    function toggleNavbar() {
      navbarCollapsed.value = !navbarCollapsed.value;
    }

    const companyService = useCompanyService();
    const companyDetail = ref<CompanyModel | null>(null);

    onMounted(async () => {
      if (localStorage.getItem('rememberMe')) companyDetail.value = await companyService.get();
    });
    const userMenuOpen = ref(false);
    function toggleUserMenu() {
      userMenuOpen.value = !userMenuOpen.value;
    }

    const { userModel, logoutAndForget } = useUserService();
    const router = useRouter();
    function logout() {
      logoutAndForget();
      router.push({ name: 'login' });
    }
    return {
      userMenuOpen,
      toggleUserMenu,
      navbarCollapsed,
      toggleNavbar,
      userModel,
      logout,
      companyDetail
    };
  }
});
