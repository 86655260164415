
import { defineComponent, ref, onMounted } from 'vue';
import { useUserService } from '@/services/userService';
import { UserModel } from '@/models/userModel';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';
import IconButton from '@/components/buttons/iconButton.vue';
import GenericModal from '@/components/beautified-containers/genericModal.vue';
import SearchBar from '@/components/inputs/searchBar.vue';
import GrayButton from '@/components/buttons/grayButton.vue';
import GreenButton from '@/components/buttons/greenButton.vue';

export default defineComponent({
  name: 'SelectUsers',
  components: {
    // Field,
    FancyBox,
    IconButton,
    GenericModal,
    SearchBar,
    GrayButton,
    GreenButton
  },
  props: {
    name: {
      type: String,
      required: true
    },
    click: {
      type: Function,
      default: () => () => {
        /**/
      }
    },
    getExistingUsers: {
      type: Function,
      required: true
    },
    inviteUserRedirect: {
      type: Function,
      default: () => () => {
        /**/
      }
    }
  },
  // emettere selectedUsers
  emits: ['usersHaveBeenSelected'],
  async setup(props) {
    const userService = useUserService();
    const { userModel } = useUserService();

    const editIsAuthorized = ref(false);
    onMounted(() => {
      editIsAuthorized.value = userModel.value?.is_admin ? userModel.value?.is_admin : false;
    });

    const allUsers = ref<Array<UserModel>>(await userService.list());
    const userList = ref<Array<UserModel>>([]);

    const modalOpen = ref(false);
    function defineUsers(): Array<UserModel> {
      const existingUsers = props.getExistingUsers();
      userList.value = allUsers.value.map((user: UserModel) => {
        let isSelected = existingUsers.some((us: UserModel) => us.email === user.email);
        user.isSelected = isSelected;
        return user;
      });
      return userList.value;
    }

    async function manageModal(e: any) {
      e.preventDefault();
      e.stopPropagation();
      modalOpen.value = !modalOpen.value;
    }
    function handleUser(user: UserModel, e: any): void {
      e.preventDefault();
      e.stopPropagation();
      user.isSelected = !user.isSelected;
      if (user.isSelected) {
        selectedUsers.value.push(user);
      } else {
        selectedUsers.value = selectedUsers.value.filter(us => us.email !== user.email);
      }
    }
    // defineUsers()
    const selectedUsers = ref(defineUsers().filter(user => user.isSelected));

    return {
      defineUsers,
      manageModal,
      modalOpen,
      handleUser,
      selectedUsers,
      allUsers,
      editIsAuthorized
    };
  }
});
