
import { defineComponent, PropType } from 'vue';
import { UserModel } from '@/models/userModel';

export default defineComponent({
  name: 'UserAvatar',
  props: {
    userModel: {
      type: Object as PropType<UserModel>,
      required: true
    }
  }
});
