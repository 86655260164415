
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconButton',
  props: {
    float: {
      type: String,
      default: () => 'center'
    },
    link: {
      type: String,
      default: () => ''
    },
    params: {
      type: Object,
      default: () => ({})
    },
    isAnimated: {
      type: Boolean,
      default: () => true
    }
  }
});
