import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

export const SUPPORT_LOCALES = ['en', 'it'];

export const i18n = createI18n({
  locale: 'it',
  fallbackLocale: 'en'
});

export function setI18nLanguage(locale: string) {
  i18n.global.locale = locale;

  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html')?.setAttribute('lang', locale);
}

export async function loadLocaleMessages(locale: string) {
  // load locale messages with dynamic import
  const messages = await import(/* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

export async function setPreferredLanguage(locale: string | undefined) {
  console.log('preferred language setted to ' + locale);
  let paramsLocale = 'en';

  if (locale && SUPPORT_LOCALES.includes(locale)) {
    paramsLocale = locale;
  }

  await loadLocaleMessages(paramsLocale);

  // set i18n language
  setI18nLanguage(paramsLocale);
}
