import { createWebHistory, createRouter, RouteLocationNormalized } from 'vue-router';
import Locations from '@/views/listing/locationsList.vue';
import Location from '@/views/detail/location.vue';
import CreateLocation from '@/views/create/createLocation.vue';
import Login from '@/views/access/login.vue';
import RegisterUser from '@/views/access/registerUser.vue';
import RegisterOrganization from '@/views/access/registerOrganization.vue';
import UpdateLocation from '@/views/update/updateLocation.vue';
import { useUserService } from '@/services/userService';
import UserProfile from '@/views/detail/user.vue';
import UpdateProfile from '@/views/update/updateUserProfile.vue';
import CompanyProfile from '@/views/detail/company.vue';
import UpdateCompanyProfile from '@/views/update/updateCompanyProfile.vue';
import RegisterUserFromInvite from '@/views/access/registerUserFromInvite.vue';
import CreateInvitation from '@/views/create/createInvitation.vue';
import RequestPasswordResetSent from '@/views/confirmation/requestPasswordResetSent.vue';
import EnterEmailToResetPassword from '@/views/access/enterEmailToResetPassword.vue';
import NewPasswordSent from '@/views/confirmation/newPasswordSent.vue';
import SetNewPassword from '@/views/access/setNewPassword.vue';
import { setPreferredLanguage } from './i18n';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/register-1/:product?/:recurring?',
      name: 'registerUser',
      component: RegisterUser,
      meta: {
        title: 'User Registration'
      }
    },
    {
      path: '/register-2/:product?/:recurring?',
      name: 'registerOrganization',
      component: RegisterOrganization,
      meta: {
        title: 'Company Registration'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/register-from-invite/:code',
      name: 'registerUserFromInvite',
      component: RegisterUserFromInvite,
      meta: {
        title: 'Complete Your Profile'
      }
    },
    {
      path: '/enter-email-to-reset-password',
      name: 'enterEmailToResetPassword',
      component: EnterEmailToResetPassword
    },
    {
      path: '/password-reset-request-sent',
      name: 'requestPasswordResetSent',
      component: RequestPasswordResetSent
    },
    {
      path: '/set-new-password/:token',
      name: 'setNewPassword',
      component: SetNewPassword
    },
    {
      path: '/new-password-confirmed',
      name: 'newPasswordSent',
      component: NewPasswordSent
    },
    {
      path: '/',
      name: 'locations',
      component: Locations,
      meta: {
        title: 'Locations'
      }
    },
    // {
    //   path: '/locations',
    //   name: 'locations',
    //   component: Locations,
    //   meta: {
    //     title: 'Locations'
    //   }
    // },
    {
      path: '/locations/:locationName',
      name: 'location',
      component: Location,
      meta: {
        title: 'Location'
      }
    },
    {
      path: '/locations/create',
      name: 'createlocation',
      component: CreateLocation,
      meta: {
        title: 'Create a Location'
      }
    },
    {
      path: '/locations/:locationName/update',
      name: 'updatelocation',
      component: UpdateLocation,
      meta: {
        title: 'Update Location'
      }
    },
    {
      path: '/user/:userId/profile',
      name: 'userprofile',
      component: UserProfile,
      meta: {
        title: 'User Profile'
      }
    },
    {
      path: '/user/:userId/profile/update',
      name: 'updateprofile',
      component: UpdateProfile,
      meta: {
        title: 'Update User Profile'
      }
    },
    {
      path: '/company/profile',
      name: 'companyprofile',
      component: CompanyProfile,
      meta: {
        title: 'Company Profile'
      }
    },
    {
      path: '/company/profile/update',
      name: 'updatecompanyprofile',
      component: UpdateCompanyProfile,
      meta: {
        title: 'Update Company Profile'
      }
    },
    {
      path: '/create-invitation',
      name: 'createinvitation',
      component: CreateInvitation,
      meta: {
        title: 'Invite users'
      }
    },
    // Al momento tutte le route non esistenti vengono reindirizzate alla home
    // è possibile creare una route 404 e reindirizzare lì
    {
      path: '/:catchAll(.*)',
      redirect: '/'
    }
  ]
});

const { userModel } = useUserService();

router.beforeEach((to: RouteLocationNormalized) => {
  document.title = to.meta.title ? `Hera Laundry | ${to.meta.title}` : 'Hera Laundry';

  // se non c'è alcun token e non si sta accedendo alle pagine di registrazione/accesso
  // reindirizza al login
  if (
    userModel.value == null &&
    to.name !== 'login' &&
    to.name !== 'registerUser' &&
    to.name !== 'registerUserFromInvite' &&
    to.name !== 'requestPasswordResetSent' &&
    to.name !== 'enterEmailToResetPassword'
  ) {
    return { name: 'login' };
  }

  // se l'utente non ha alcuna organizzazione associata e non sta accedendo alla pagina di creazione organizzazione
  // reindirizza alla pagina di creazione organizzazione
  if (userModel.value?.organization === null && to.name !== 'registerOrganization') {
    return { name: 'registerOrganization' };
  }

  return true;
});

router.beforeEach(async (to, from, next) => {
  setPreferredLanguage(userModel.value?.preferred_language);

  return next();
});

export default router;
