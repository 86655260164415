import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "md:flex md:items-center p-12" }
const _hoisted_2 = { class: "md:w-1/2" }
const _hoisted_3 = { class: "md:w-1/2 px-8" }
const _hoisted_4 = { class: "font-semibold mb-6 text-xl" }
const _hoisted_5 = { class: "table-fixed w-full" }
const _hoisted_6 = { class: "border-b-2 border-blue-400" }
const _hoisted_7 = { class: "w-1/2 text-left" }
const _hoisted_8 = { class: "w-1/4 text-center" }
const _hoisted_9 = { class: "w-1/4 text-center" }
const _hoisted_10 = { class: "mt-4" }
const _hoisted_11 = { class: "bg-green-50 text-center" }
const _hoisted_12 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PieChart = _resolveComponent("PieChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PieChart, { chartData: _ctx.chartData }, null, 8, ["chartData"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('consumptionsChart.summary')), 1),
      _createElementVNode("table", _hoisted_5, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_6, [
            _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('consumptionsChart.source')), 1),
            _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('consumptionsChart.quantity')), 1),
            _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('consumptionsChart.unitOfMeasure')), 1)
          ])
        ]),
        _createElementVNode("tbody", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedData, (data, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", {
                class: "bg-blue-50 p-2",
                style: _normalizeStyle([{"text-decoration":"underline","text-decoration-thickness":"5px"}, { 'text-decoration-color': data.color }])
              }, _toDisplayString(data.type), 5),
              _createElementVNode("td", _hoisted_11, _toDisplayString(data.qty != 0 ? data.qty.toFixed(1) : '-'), 1),
              _createElementVNode("td", _hoisted_12, _toDisplayString(data.udm), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}