import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-none bg-gray-200 w-12 h-12 rounded-full" }
const _hoisted_2 = { class: "bg-green-400 rounded-full w-6 h-6 px-1" }
const _hoisted_3 = { class: "text-xs font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.userModel.first_name.charAt(0).toUpperCase() + _ctx.userModel.last_name.charAt(0).toUpperCase()), 1)
    ])
  ]))
}