import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-col min-h-screen" }
const _hoisted_3 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_4 = { class: "max-w-full md:max-w-5xl lg:max-w-md mx-auto pt-12" }
const _hoisted_5 = { class: "my-6 text-center font-semibold py-6 border-2 border-white rounded-lg bg-gray-100 bg-opacity-50" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "flex flex-col min-h-screen" }
const _hoisted_8 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_9 = { class: "max-w-full md:max-w-5xl lg:max-w-md mx-auto pt-12" }
const _hoisted_10 = {
  key: 0,
  class: "my-6 text-center font-semibold py-6 border-2 border-white rounded-lg bg-gray-100 bg-opacity-50"
}
const _hoisted_11 = { class: "rounded-lg bg-white p-12 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Form = _resolveComponent("Form")!

  return (!_ctx.invitation)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_NavBar, {
            pageTitle: "Invito non disponibile",
            hasMenu: false,
            titleIsCentered: true
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('registerUserFromInvite.$4')), 1)
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_NavBar, {
            pageTitle: "Completa il tuo account per accedere",
            hasMenu: false,
            titleIsCentered: true
          }),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.postFailed)
                ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('registerUserFromInvite.$4')), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_Form, {
                onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.register($event as any)))
              }, {
                default: _withCtx(({ meta: formMeta, values }) => [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_TextField, {
                      name: "first_name",
                      rules: "required",
                      placeholder: "$t('registerUserFromInvite.firstNamePlaceHolder')"
                    }),
                    _createVNode(_component_TextField, {
                      name: "last_name",
                      rules: "required",
                      placeholder: "$t('registerUserFromInvite.lastNamePlaceHolder')"
                    }),
                    _createVNode(_component_TextField, {
                      name: "password",
                      type: "password",
                      rules: "required|min:8",
                      placeholder: "$t('registerUserFromInvite.passwordPlaceHolder')"
                    }),
                    _createVNode(_component_TextField, {
                      name: "confirmed_password",
                      type: "password",
                      rules: "required|confirmed:@password",
                      placeholder: "$t('registerUserFromInvite.confirmedPasswordPlaceHolder')"
                    })
                  ]),
                  _createVNode(_component_FormButton, {
                    disabled: !formMeta.valid,
                    float: "center",
                    title: "$t('registerUserFromInvite.confirmation')"
                  }, null, 8, ["disabled"])
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]))
}