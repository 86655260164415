
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SimpleCard',
  //   props: {
  //     list: {
  //       type: Array,
  //       required: true
  //     },
  //   }
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: () => ''
    }
  }
});
