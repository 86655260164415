import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen" }
const _hoisted_2 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_3 = { class: "max-w-full md:max-w-5xl lg:max-w-md mx-auto pt-12" }
const _hoisted_4 = {
  key: 0,
  class: "mb-2 py-4 px-6 border-2 rounded-xl border-white font-semibold"
}
const _hoisted_5 = { class: "rounded-lg bg-white p-12 mb-6" }
const _hoisted_6 = { class: "font-semibold text-center pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, {
      pageTitle: "Imposta una nuova password",
      hasMenu: false,
      titleIsCentered: true
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.resetFailed)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('setNewPassword.error')), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_Form, {
          onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendNewPassword($event as any)))
        }, {
          default: _withCtx(({ meta: formMeta }) => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('setNewPassword.setNewPwsd')), 1),
              _createVNode(_component_TextField, {
                name: "password",
                type: "password",
                rules: "required|min:8",
                placeholder: _ctx.$t('setNewPassword.passwordPlaceHolder')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_TextField, {
                name: "confirmed_password",
                type: "password",
                rules: "required|confirmed:@password",
                placeholder: _ctx.$t('setNewPassword.confirmedPasswordPlaceHolder')
              }, null, 8, ["placeholder"])
            ]),
            _createVNode(_component_FormButton, {
              type: "submit",
              disabled: !formMeta.valid,
              float: "center",
              title: _ctx.$t('setNewPassword.confirmation')
            }, null, 8, ["disabled", "title"])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}