
import { defineComponent, ref } from 'vue';
import { useUserService } from '@/services/userService';
import { useRouter } from 'vue-router';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form } from 'vee-validate';
import '@/forms';

export default defineComponent({
  name: 'EnterEmailToResetPassword',
  components: {
    NavBar,
    TextField,
    FormButton,
    Form
  },
  setup() {
    const userService = useUserService();
    const router = useRouter();
    const resetFailed = ref(false);
    async function requestPasswordReset(email: string) {
      resetFailed.value = false;
      try {
        await userService.requestPasswordReset(email);
        router.push({ name: 'requestPasswordResetSent' });
      } catch (e) {
        resetFailed.value = true;
      }
    }
    return { requestPasswordReset, resetFailed };
  }
});
