import axios from 'axios';
import { CompanyModel, SubscriptionModel } from '@/models/companyModel';
import { useUserService } from './userService';
import { serverUrl } from './constants';

const { userModel } = useUserService();

export type CompanyModelWithUsers = CompanyModel & {
  users: Array<{
    email: string;
    first_name: string;
    last_name: string;
    is_admin: boolean;
    registration_date: string;
    locations: string;
  }>;
};

export function useCompanyService() {
  return {
    async get(): Promise<CompanyModelWithUsers> {
      const response = await axios.get<CompanyModelWithUsers>(serverUrl + '/organization');
      return response.data;
    },

    async getSubscription(): Promise<SubscriptionModel> {
      const response = await axios.get<SubscriptionModel>(serverUrl + '/subscription');
      return response.data;
    },

    async create(newCompany: CompanyModel): Promise<CompanyModel> {
      // create company
      const response = await axios.post<CompanyModel>(serverUrl + '/organization/create', newCompany);

      // go to checkout

      return response.data;
    },

    async checkout(product: string, recurring: string): Promise<string> {
      const response = await axios.get(serverUrl + `/checkout/${product}/${recurring}/stripe`);
      if (response.status === 501) {
        throw new TypeError('User not allowed');
      }
      if (response.status === 404) {
        throw new TypeError('Product not found');
      }
      if (response.data.url === null) {
        throw new TypeError('Please try again later');
      }
      return response.data.url;
    },

    async update(updatedCompany: CompanyModel): Promise<CompanyModel> {
      const response = await axios.post<CompanyModel>(serverUrl + '/organization/edit', updatedCompany);
      if (userModel.value) userModel.value.organization = response.data.company_name;
      return response.data;
    }
  };
}
