import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", { 'justify-end': _ctx.float === 'right', 'justify-start': _ctx.float === 'left', 'justify-center': _ctx.float === 'center' }])
  }, [
    (_ctx.link)
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          to: { name: _ctx.link, params: _ctx.params }
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: _normalizeClass(["group px-2 py-2 rounded-md border-2 border-cblue text-blue-500 hover:bg-blue-500 hover:text-white rounded-full", { 'animate-pulse': _ctx.isAnimated }])
            }, [
              _renderSlot(_ctx.$slots, "svgicon")
            ], 2)
          ]),
          _: 3
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: _normalizeClass(["group px-2 py-2 rounded-md border-2 border-cblue text-blue-500 hover:bg-blue-500 hover:text-white rounded-full", { 'animate-pulse': _ctx.isAnimated }])
        }, [
          _renderSlot(_ctx.$slots, "svgicon")
        ], 2))
  ], 2))
}