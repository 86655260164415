
import { defineComponent, onErrorCaptured, onMounted, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';
import { useRoute, useRouter } from 'vue-router';
import { CompanyModel } from '@/models/companyModel';
import { useCompanyService } from '@/services/companyService';
import TextField from '@/components/inputs/textField.vue';
import { Form } from 'vee-validate';
import FormButton from '@/components/buttons/formButton.vue';

export default defineComponent({
  name: 'UpdateCompanyProfile',
  components: {
    NavBar,
    FancyBox,
    TextField,
    Form,
    FormButton
  },
  setup() {
    const companyService = useCompanyService();
    const route = useRoute();
    const company = ref<CompanyModel | null>(null);
    const formValues = ref<CompanyModel | null>(null);
    const companyId = route.params.companyId as string;
    // la GET che recupera i valori correnti della company
    onMounted(async () => {
      company.value = await companyService.get();
      // Popoliamo i valori iniziali del form con i valori del sistema corrente
      formValues.value = company.value;
    });
    const router = useRouter();
    const postFailed = ref(false);
    async function updateCompany(updatedOrg: CompanyModel) {
      console.log(updatedOrg);
      try {
        await companyService.update(updatedOrg);
        router.push({ name: 'companyprofile', params: { companyId: companyId } });
      } catch (e) {
        postFailed.value = true;
        console.log(e);
      }
    }

    const errorMessage = ref<string | null>(null);
    onErrorCaptured((e: unknown) => {
      errorMessage.value = (e as Error).message;
      return false;
    });

    return { errorMessage, formValues, postFailed, updateCompany, company };
  }
});
