import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "group w-auto md:w-1/6 p-2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" }
const _hoisted_2 = { class: "px-4 py-3 bg-gray-100 rounded-t-xl" }
const _hoisted_3 = { class: "text-sm font-semibold text-center uppercase" }
const _hoisted_4 = { class: "bg-white rounded-b-xl py-3 border-2 border-gray-100" }
const _hoisted_5 = {
  key: 0,
  class: "text-xl text-center font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.value), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}