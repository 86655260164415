
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { Chart, PieController, ArcElement, CategoryScale, Legend, Tooltip } from 'chart.js';
import { useErrorLogService } from '@/services/machineService';
import SimpleCard from '@/components/beautified-containers/simpleCard.vue';
import { ErrorLogModel } from '@/models/machineModel';
import { formatDate } from '@/date';
Chart.register(PieController, ArcElement, CategoryScale, Legend, Tooltip);

export default defineComponent({
  name: 'ErrorsTable',
  components: {
    SimpleCard
  },
  props: {
    loc: {
      type: String,
      required: true
    },
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const errorLogService = useErrorLogService();
    // const errorLogs = ref(await errorLogService.get(props.loc, props.start, props.end));
    const errorLogs = ref<ErrorLogModel>();

    onMounted(async () => {
      errorLogs.value = await errorLogService.get(props.loc, props.start, props.end);
    });

    watchEffect(async () => {
      errorLogs.value = await errorLogService.get(props.loc, props.start, props.end);
    });
    return { errorLogs, formatDate };
  }
});
