
import { defineComponent, onErrorCaptured, onMounted, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import TextArea from '@/components/inputs/textArea.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form } from 'vee-validate';
// import SelectUsers from '@/components/inputs/selectUsers.vue';
import { LocationModel } from '@/models/locationModel';
import { useLocationService } from '@/services/locationService';
import { useRoute, useRouter } from 'vue-router';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';

export default defineComponent({
  name: 'UpdateLocation',
  components: {
    NavBar,
    FancyBox,
    TextField,
    TextArea,
    FormButton,
    Form
    // SelectUsers
  },
  setup() {
    const postFailed = ref(false);
    const locationService = useLocationService();
    // Route current
    const route = useRoute();
    // Router con tutte le sue properties (push...)
    const router = useRouter();
    // Ricaviamo l'id del sistema corrente dai parametri dell'url
    const locationName = route.params.locationName as string;
    // Definiamo la variabile currentLocation da popolare mediante una get per fornire i valori
    // iniziali - formValues - al form
    const currentLocation = ref<LocationModel | null>(null);
    const formValues = ref<LocationModel | null>(null);

    // la GET che recupera i valori del sistema corrente
    onMounted(async () => {
      currentLocation.value = await locationService.get(locationName);
      // Popoliamo i valori iniziali del form con i valori del sistema corrente
      formValues.value = currentLocation.value;
    });

    async function updateLocation(newLocation: LocationModel) {
      try {
        let formattedLocation = {
          location_name: newLocation.location_name,
          address: newLocation.address
        };
        const response = await locationService.update(locationName, formattedLocation);
        router.push({ name: 'location', params: { locationName: response.location_name } });
      } catch (e) {
        postFailed.value = true;
        console.log(e);
      }
    }
    const errorMessage = ref<string | null>(null);
    onErrorCaptured((e: unknown) => {
      errorMessage.value = (e as Error).message;
      return false;
    });
    return {
      formValues,
      currentLocation,
      errorMessage,
      updateLocation,
      postFailed
    };
  }
});
