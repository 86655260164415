
import NavBar from '@/components/header/navbar.vue';
import FancyCard from '@/components/beautified-containers/fancyCard.vue';
import IconButton from '@/components/buttons/iconButton.vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useLocationService } from '@/services/locationService';
import { LocationModel } from '@/models/locationModel';
import { useUserService } from '@/services/userService';

export default defineComponent({
  name: 'Locations',
  components: {
    NavBar,
    FancyCard,
    IconButton
  },
  setup() {
    const locationService = useLocationService();
    const { userModel } = useUserService();
    const editIsAuthorized = ref<boolean>(false);
    const locations = ref<Array<LocationModel> | null>(null);

    onMounted(async () => {
      editIsAuthorized.value = userModel.value?.is_admin ? userModel.value?.is_admin : false;
      locations.value = await locationService.list();
    });
    return {
      editIsAuthorized,
      locations
    };
  }
});
