
import { defineComponent, ref } from 'vue';
import { useUserService } from '@/services/userService';
import { useRouter } from 'vue-router';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form } from 'vee-validate';
import '@/forms';

export default defineComponent({
  name: 'Login',
  components: {
    NavBar,
    TextField,
    FormButton,
    Form
  },
  setup() {
    const userService = useUserService();
    const router = useRouter();
    const authenticationFailed = ref(false);

    async function authenticate(credentials: { email: string; password: string }) {
      authenticationFailed.value = false;
      try {
        console.log(credentials);
        await userService.authenticate(credentials);
        router.push({ name: 'locations' });
      } catch (e) {
        console.log(e);
        authenticationFailed.value = true;
      }
    }

    return { authenticate, authenticationFailed };
  }
});
