import axios from 'axios';
import { LocationModel } from '@/models/locationModel';
import { serverUrl } from './constants';

export function useLocationService() {
  return {
    async list(): Promise<Array<LocationModel>> {
      const response = await axios.get<Array<LocationModel>>(serverUrl + '/locations');
      return response.data;
    },

    async get(location_name: string): Promise<LocationModel> {
      const response = await axios.get<LocationModel>(serverUrl + `/locations/${location_name}`, {
        // params: { status: 'PENDING' }
      });
      return response.data;
    },

    async create(newLocation: LocationModel): Promise<LocationModel> {
      const response = await axios.post<LocationModel>(serverUrl + '/locations/create', newLocation);
      return response.data;
    },

    //Verificare, forse non corretta (occorre passare tutti i nuovi parametri?)
    async update(locationName: string, updatedLocation: any): Promise<LocationModel> {
      console.log(updatedLocation);
      const response = await axios.post(serverUrl + `/locations/${locationName}/edit`, updatedLocation);
      return response.data;
    },

    async delete(locationName: string): Promise<void> {
      await axios.post(serverUrl + `/locations/${locationName}/delete`);
    }
  };
}
