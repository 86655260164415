import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group hover:shadow-lg rounded-xl h-full" }
const _hoisted_2 = { class: "bg-gray-50 rounded-t-xl px-16 py-4 h-24" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "bg-gradient-to-br from-gray-100 to-gray-300 py-4 rounded-b-2xl px-16" }
const _hoisted_5 = { class: "text-xl font-semibold text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "svgicon"),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.title), 1)
    ])
  ]))
}