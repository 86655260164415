import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", { 'justify-end': _ctx.float === 'right', 'justify-start': _ctx.float === 'left', 'justify-center': _ctx.float === 'center' }])
  }, [
    _createElementVNode("button", {
      class: "transition duration-500 ease-in-out transform hover:scale-110 bg-gray-50 py-4 px-6 rounded-full text-blue-500 font-semibold",
      disabled: _ctx.disabled
    }, _toDisplayString(_ctx.title), 9, _hoisted_1)
  ], 2))
}