
import { defineComponent } from 'vue';
import { ErrorMessage, Field } from 'vee-validate';

export default defineComponent({
  name: 'TextArea',
  components: {
    ErrorMessage,
    Field
  },
  props: {
    rows: {
      type: Number,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
});
