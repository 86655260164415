
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReadOnlyText',
  props: {
    label: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
  // setup(props) {
  //   const {
  //     value: inputValue,
  //     errorMessage,
  //     handleBlur,
  //     handleChange,
  //     meta
  //   } = useField(props.name, undefined, {
  //     initialValue: props.value
  //   });

  //   return {
  //     handleChange,
  //     handleBlur,
  //     errorMessage,
  //     inputValue,
  //     meta
  //   };
  // }
});
