import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-2" }
const _hoisted_2 = { class: "font-semibold pl-4" }
const _hoisted_3 = { class: "rounded-xl bg-gray-100 w-full focus:outline-none focus:ring-2 focus:ring-gray-200 px-8 py-2 text-lg placeholder-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ]))
}