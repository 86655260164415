import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen" }
const _hoisted_2 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_3 = { class: "max-w-full md:max-w-5xl lg:max-w-md mx-auto pt-12" }
const _hoisted_4 = { class: "rounded-lg bg-white p-12 mb-6" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "rounded-lg bg-white p-6 mb-6" }
const _hoisted_7 = { class: "flex justify-between items-center" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "italic" }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 px-12 py-6" }
const _hoisted_13 = { class: "font-semibold text-xl text-center" }
const _hoisted_14 = { class: "grid grid-cols-1 gap-y-2 py-4 px-8" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "flex justify-between items-center" }
const _hoisted_17 = { class: "font-semibold" }
const _hoisted_18 = { class: "italic" }
const _hoisted_19 = { class: "flex justify-center space-x-2 mt-4" }
const _hoisted_20 = { class: "text-sm" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "font-semibold" }
const _hoisted_23 = { class: "flex justify-center space-x-2 py-4 bg-gray-200 rounded-b-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_LightButton = _resolveComponent("LightButton")!
  const _component_GrayButton = _resolveComponent("GrayButton")!
  const _component_GenericModal = _resolveComponent("GenericModal")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, {
      pageTitle: "Registrazione | Step 2/2",
      hasMenu: true,
      titleIsCentered: true
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Form, {
          onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.registerCom($event as any)))
        }, {
          default: _withCtx(({ meta: formMeta, values }) => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_TextField, {
                name: "company_name",
                rules: "required",
                placeholder: _ctx.$t('registerOrganization.buisnessNamePlaceholder')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_TextField, {
                name: "address",
                rules: "required",
                placeholder: _ctx.$t('registerOrganization.addressPlaceholder')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_TextField, {
                name: "postal_code",
                rules: "required",
                placeholder: _ctx.$t('registerOrganization.postalCodePlaceholder')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_TextField, {
                name: "city",
                rules: "required",
                placeholder: _ctx.$t('registerOrganization.cityPlaceholder')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_TextField, {
                name: "state",
                rules: "required",
                placeholder: _ctx.$t('registerOrganization.provincePlaceholder')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_TextField, {
                name: "country",
                rules: "required",
                placeholder: _ctx.$t('registerOrganization.countryPlaceholder')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_TextField, {
                name: "vat",
                rules: "required",
                placeholder: _ctx.$t('registerOrganization.vatNumberPlaceholder')
              }, null, 8, ["placeholder"]),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_TextField, {
                  name: "fiscal_code",
                  placeholder: _ctx.$t('registerOrganization.fiscalCodePlaceholder')
                }, null, 8, ["placeholder"]),
                _createVNode(_component_TextField, {
                  name: "sdi_code",
                  placeholder: _ctx.$t('registerOrganization.sdiCodePlaceholder')
                }, null, 8, ["placeholder"])
              ], 512), [
                [_vShow, ['Italia', 'Italy'].includes(values.country)]
              ])
            ]),
            (_ctx.product)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", null, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.product), 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('registerOrganization.description')), 1)
                      ]),
                      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('registerOrganization.eurBilledAnn', { cost: -1, recurring: -1 })), 1)
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_LightButton, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalOpen = true)),
                    title: "Select a plan"
                  }),
                  (_ctx.modalOpen)
                    ? (_openBlock(), _createBlock(_component_GenericModal, { key: 0 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('registerOrganization.selectPlan')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: plan.key
                              }, [
                                _createElementVNode("div", {
                                  onClick: _withModifiers(($event: any) => (_ctx.planSelect(plan.key)), ["prevent"]),
                                  class: _normalizeClass(["rounded-lg bg-gray-100 p-6 border-2 border-white", { 'border-green-400': plan.selected }])
                                }, [
                                  _createElementVNode("div", _hoisted_16, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("p", _hoisted_17, _toDisplayString(plan.name), 1),
                                      _createElementVNode("p", _hoisted_18, _toDisplayString(plan.description), 1),
                                      _createElementVNode("div", _hoisted_19, [
                                        _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('registerOrganization.billed')), 1),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plan.rates, (rate) => {
                                          return (_openBlock(), _createElementBlock("span", {
                                            key: rate.key
                                          }, [
                                            _createElementVNode("button", {
                                              onClick: ($event: any) => (_ctx.rateSelect(plan.key, rate.key)),
                                              class: _normalizeClass(["px-2 py-0.5 text-sm bg-white rounded-full", { 'bg-green-400': rate.selected }])
                                            }, _toDisplayString(rate.recurring_in_month), 11, _hoisted_21)
                                          ]))
                                        }), 128))
                                      ])
                                    ]),
                                    _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('registerOrganization.eurBilledAnn', _ctx.getCostAndBilled(plan.key, plan.selected_rate_index))), 1)
                                  ])
                                ], 10, _hoisted_15)
                              ]))
                            }), 128))
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_GrayButton, {
                              title: _ctx.$t('registerOrganization.saveAndExit'),
                              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.savePlanSelection(), (_ctx.modalOpen = false)), ["prevent"]))
                            }, null, 8, ["title"])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])),
            (_ctx.product)
              ? (_openBlock(), _createBlock(_component_FormButton, {
                  key: 2,
                  type: "submit",
                  disabled: !formMeta.valid,
                  float: "center",
                  title: _ctx.$t('registerOrganization.confirmAndPay')
                }, null, 8, ["disabled", "title"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}