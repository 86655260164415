import { ref } from 'vue';
import { UserModel } from '@/models/userModel';
import axios, { AxiosRequestConfig } from 'axios';
import { serverUrl } from './constants';
import router from '@/router';
import { setPreferredLanguage } from '@/i18n';
import { setPreferredDateFormat } from '@/date';

export function retrieveUser(): UserModel | null {
  const userAsString = window.localStorage.getItem('rememberMe');
  return userAsString ? JSON.parse(userAsString) : null;
}

const userModel = ref<UserModel | null>(retrieveUser());

function storeLoggedInUser(user: UserModel, token?: string): void {
  console.log('storeLoggedInUser', user, token);
  let temp_token = token;
  if (!temp_token) {
    temp_token = userModel.value?.token;
  }

  userModel.value = user;
  userModel.value.token = temp_token;

  window.localStorage.setItem('rememberMe', JSON.stringify(user));

  //set preferred language
  setPreferredLanguage(userModel.value.preferred_language);
  setPreferredDateFormat(userModel.value.date_format);
}

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (userModel.value) {
    config.headers!.Authorization = `Token ${userModel.value.token}`;
  }
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data) {
      if (error.response.data?.detail && error.response.data.detail == 'Invalid token.') {
        // delete user data
        window.localStorage.clear();
        router.push({ name: 'login' });
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

async function authenticate(credentials: { email: string; password: string }): Promise<UserModel> {
  // get token
  const response_token = await axios.post(serverUrl + '/auth/signin', credentials);

  // get user data
  const response_user = await axios.get(serverUrl + '/user', {
    headers: {
      Authorization: 'Token ' + response_token.data
    }
  });

  storeLoggedInUser(response_user.data, response_token.data);

  return response_user.data;
}

async function registerAdmin(newUser: UserModel & { password: string }): Promise<UserModel> {
  // clear local storage and all data
  window.localStorage.clear();
  if (userModel.value?.token) userModel.value.token = undefined;

  // create user
  await axios.post(serverUrl + '/user/create', newUser);

  //authenticate user
  return await authenticate({ email: newUser.email, password: newUser.password });
}

async function registerUser(newUser: UserModel): Promise<UserModel> {
  const response = await axios.post(serverUrl + '/auth/register-user/', newUser);
  storeLoggedInUser(response.data.user, response.data.token);
  return response.data;
}

async function update(updatedUser: UserModel): Promise<UserModel> {
  const response = await axios.post<UserModel>(serverUrl + '/user/edit', updatedUser);
  storeLoggedInUser(response.data);
  return response.data;
}

function logoutAndForget(): void {
  userModel.value = null;
  window.localStorage.removeItem('rememberMe');
}

async function list(): Promise<Array<UserModel>> {
  const response = await axios.get<Array<UserModel>>(serverUrl + '/users');
  return response.data;
}

async function get(userEmail?: string): Promise<UserModel> {
  let response;
  if (userEmail) {
    response = await axios.get<UserModel>(serverUrl + `/users/${userEmail}`);
  } else {
    // get my user
    response = await axios.get<UserModel>(serverUrl + '/user');
    storeLoggedInUser(response.data);
  }
  return response.data;
}

// Gestisce la richiesta di nuova password facendo inserire l'email dell'account
// all'indirizzo email specificato, se esiste come account, verrà inviata email con link per recupero password
async function requestPasswordReset(email: string): Promise<boolean> {
  const response = await axios.post<boolean>(serverUrl + `/auth/password_reset/`, email);
  return response.data;
}

// Gestisce la richiesta di nuova password facendo inserire l'email dell'account
// all'indirizzo email specificato, se esiste come account, verrà inviata email con link per recupero password
async function sendNewPassword(credentials: { password: string; token: string }): Promise<boolean> {
  const response = await axios.post<boolean>(serverUrl + `/auth/password_reset/confirm/?token=${credentials.token}`, credentials);
  return response.data;
}

export function useUserService() {
  return {
    userModel,
    registerAdmin,
    registerUser,
    authenticate,
    update,
    logoutAndForget,
    list,
    get,
    requestPasswordReset,
    sendNewPassword,
    async delete(userEmail: string): Promise<void> {
      await axios.delete(serverUrl + `/users?to_delete=${userEmail}`); //TODO
    }
  };
}
