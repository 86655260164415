
import { defineComponent, onMounted, onErrorCaptured, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';
import IconButton from '@/components/buttons/iconButton.vue';
import { LocationModel } from '@/models/locationModel';
import { useRoute, useRouter } from 'vue-router';
import { useLocationService } from '@/services/locationService';
import { useUserService } from '@/services/userService';
import TextField from '@/components/inputs/textField.vue';
import { Form } from 'vee-validate';
// import ModalMessage from '@/components/beautified-containers/modalMessage.vue';
import GenericModal from '@/components/beautified-containers/genericModal.vue';
import GrayButton from '@/components/buttons/grayButton.vue';
import RedButton from '@/components/buttons/redButton.vue';
// import GreenButton from '@/components/buttons/greenButton.vue';
import FormButton from '@/components/buttons/formButton.vue';
import SimpleCard from '@/components/beautified-containers/simpleCard.vue';
import ConsumptionsChart from '@/charts/consumptionsChart.vue';
import ProductivityTable from '@/charts/productivityTable.vue';
import ErrorsTable from '@/charts/errorsTable.vue';
import { useMachineService } from '@/services/machineService';
import SelectUsers from '@/components/inputs/selectUsers.vue';
import { MachineModel } from '@/models/machineModel';
import { UserModel } from '@/models/userModel';
import { formatDate } from '@/date';
import { useDatepickerStore } from '@/stores/datepickerStore';
import { storeToRefs } from 'pinia';

type TabString = 'generality' | 'cost&consumption' | 'productivity' | 'errors&anomalies' | 'machines';

export default defineComponent({
  name: 'Location',
  components: {
    NavBar,
    IconButton,
    // FancyCard,
    FancyBox,
    // UserAvatar,
    // ModalMessage,
    GenericModal,
    GrayButton,
    RedButton,
    // GreenButton,
    SimpleCard,
    // DeleteIcon,
    ConsumptionsChart,
    ProductivityTable,
    ErrorsTable,
    TextField,
    Form,
    FormButton,
    SelectUsers
  },
  setup() {
    const datepickerStore = useDatepickerStore();
    const { userModel } = useUserService();
    const location = ref<LocationModel | null>(null);
    const editIsAuthorized = ref<boolean>(false);

    const route = useRoute();
    const locationName = route.params.locationName as string;
    const locationService = useLocationService();
    const machineService = useMachineService();
    const bestPerformanceMachineName = ref('');
    const machinesList = ref<Array<MachineModel>>([]);
    const activatedMachinesList = ref<Array<MachineModel>>([]);
    const toActivateMachinesList = ref<Array<MachineModel>>([]);

    async function fetchMachinesList() {
      machinesList.value = await machineService.list(locationName);
      activatedMachinesList.value = machinesList.value.filter(m => !m?.activation_key);
      toActivateMachinesList.value = machinesList.value.filter(m => m?.activation_key);
      // TODO
      // if (false && machinesList.value.length > 0) {
      //   const bestPerformanceMachine = machinesList.value.reduce((prev, curr) => {
      //     return prev
      //   });
      //   bestPerformanceMachineName.value = bestPerformanceMachine.name;
      // }
    }

    onMounted(async () => {
      editIsAuthorized.value = userModel.value?.is_admin ? userModel.value?.is_admin : false;
      location.value = await locationService.get(locationName);
      console.log(location.value);
      await fetchMachinesList();
    });

    const deleteModal = ref(false);

    const router = useRouter();
    function inviteUserRedirect() {
      router.push({ name: 'createinvitation' });
    }

    async function updateLocationUsers(users: Array<UserModel>) {
      let formattedLocation = {
        users: users.map(user => user.email)
      };
      location.value = await locationService.update(locationName, formattedLocation);
      console.log(location.value);
    }

    function getLocationUsers() {
      return location.value?.users ? location.value.users : [];
    }

    async function deleteLocation(locationName: string) {
      try {
        await locationService.delete(locationName);
        deleteModal.value = false;
        router.push({ name: 'locations' });
      } catch (error) {
        console.log(error);
      }
    }
    const openTab = ref<TabString>('generality');
    function toggleTabs(tabNumber: TabString) {
      openTab.value = tabNumber;
    }

    const isDeleteModalOpen = ref(false);
    async function deleteMachine(name: string) {
      try {
        await machineService.delete(name);
        isDeleteModalOpen.value = false;
      } catch (error) {
        console.log('Ohi');
      }
    }

    const isNewMacModalOpen = ref(false);

    async function createMachine(value: { title: string }) {
      console.log(value.title);
      isNewMacModalOpen.value = false;
      await machineService.create(locationName, value.title);

      await fetchMachinesList();
    }

    // Varibili per la gestione della selezione del range di date
    const yesterday = storeToRefs(datepickerStore).yesterday;
    const sixmonthsago = storeToRefs(datepickerStore).sixmonthsago;
    const start_date = storeToRefs(datepickerStore).start_date;
    const end_date = storeToRefs(datepickerStore).end_date; //ref('2022-01-31');

    const errorMessage = ref<string | null>(null);
    onErrorCaptured((e: unknown) => {
      console.log(e as Error);
      errorMessage.value = (e as Error).message;
      return false;
    });
    return {
      activatedMachinesList,
      toActivateMachinesList,
      machinesList,
      locationName,
      start_date,
      end_date,
      yesterday,
      sixmonthsago,
      openTab,
      toggleTabs,
      userModel,
      location,
      errorMessage,
      isNewMacModalOpen,
      createMachine,
      deleteModal,
      isDeleteModalOpen,
      deleteMachine,
      deleteLocation,
      inviteUserRedirect,
      updateLocationUsers,
      getLocationUsers,
      editIsAuthorized,
      formatDate,
      bestPerformanceMachineName
    };
  }
});
