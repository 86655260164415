import axios from 'axios';
import { PlanModel } from '@/models/planModel';
import { serverUrl } from './constants';

export function usePlanService() {
  return {
    async get(productKey: string, rateKey: string): Promise<Array<PlanModel>> {
      let targetQuery = '';
      if (productKey != null && rateKey != null) {
        targetQuery = '?p_key=' + productKey + '&r_key=' + rateKey;
      }
      const response = await axios.get<Array<PlanModel>>(serverUrl + '/products' + targetQuery);
      return response.data;
    }
  };
}
