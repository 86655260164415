
import { defineComponent, ref, watchEffect } from 'vue';
import { PieChart } from 'vue-chart-3';
import { Chart, PieController, ArcElement, CategoryScale, Legend, Tooltip } from 'chart.js';
import { useConsService } from '@/services/machineService';
import { ConsumptionModel } from '@/models/machineModel';
Chart.register(PieController, ArcElement, CategoryScale, Legend, Tooltip);

export default defineComponent({
  name: 'ConsumptionsChart',
  components: {
    PieChart
  },
  props: {
    loc: {
      type: String,
      required: true
    },
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    },
    showlogs: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const consService = useConsService();
    const consumptions = ref<ConsumptionModel>();

    const formattedData = ref<
      Array<{
        type: string;
        qty: number;
        udm: string;
        currency: string;
        amount: number;
        color: string;
      }>
    >([]);
    const chartData = ref();

    const backgroundColors = ['#', '#', '#', '#', '#', '#'];

    watchEffect(async () => {
      const consResult = await consService.get(props.loc, props.start, props.end, props.showlogs);
      const elements = [
        {
          type: 'Chimico 1',
          qty: consResult.total_c1_consumption / 1000,
          udm: 'LT',
          currency: consResult.chemical_currency,
          amount: consResult.total_c1_cost,
          color: '#35B775'
        },
        {
          type: 'Chimico 2',
          qty: consResult.total_c2_consumption / 1000,
          udm: 'LT',
          currency: 'EUR',
          amount: consResult.total_c2_cost,
          color: '#FFBECE'
        },
        {
          type: 'Chimico 3',
          qty: consResult.total_c3_consumption / 1000,
          udm: 'LT',
          currency: 'EUR',
          amount: consResult.total_c3_cost,
          color: '#A398D0'
        },
        {
          type: 'Chimico 4',
          qty: consResult.total_c4_consumption / 1000,
          udm: 'LT',
          currency: 'EUR',
          amount: consResult.total_c4_cost,
          color: '#33CCCC'
        },
        {
          type: 'Acqua',
          qty: consResult.total_water,
          udm: 'LT',
          currency: 'EUR',
          amount: 0,
          color: '#2196F3'
        },
        {
          type: 'Elettricità',
          qty: consResult.total_electricity,
          udm: 'KW',
          currency: 'EUR',
          amount: 0,
          color: '#FFE23E'
        }
      ];

      consumptions.value = consResult;

      const filteredElements = [...elements].splice(0, 4);

      formattedData.value = elements;
      chartData.value = {
        labels: filteredElements.map(el => el.type),
        datasets: [
          {
            data: filteredElements.map(el => el.qty),
            backgroundColor: filteredElements.map(el => el.color)
          }
        ]
      };
    });
    return { chartData, formattedData, backgroundColors };
  }
});
