
import { defineComponent } from 'vue';
// import NavBar from './components/header/navbar.vue';
// import Footer from '@/components/footer/footer.vue';
export default defineComponent({
  name: 'App',
  props: {
    pageTitle: {
      type: String,
      default: () => ''
    }
  }
});
