import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-2" }
const _hoisted_2 = { class: "font-semibold pl-4" }
const _hoisted_3 = ["type", "placeholder", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, { name: _ctx.name }, {
    default: _withCtx(({ field }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createElementVNode("input", _mergeProps({
          type: _ctx.type,
          class: "rounded-xl bg-gray-100 w-full focus:outline-none focus:ring-2 focus:ring-gray-200 px-8 py-2 text-lg placeholder-gray-500",
          placeholder: _ctx.placeholder,
          id: _ctx.name
        }, field), null, 16, _hoisted_3),
        _createVNode(_component_ErrorMessage, {
          name: _ctx.name,
          class: "px-8 font-semibold bg-gray-50 bg-opacity-60 text-red-500 rounded-full py-2"
        }, null, 8, ["name"])
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}