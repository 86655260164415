
import { defineComponent, onErrorCaptured, onMounted, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';
import { useRouter } from 'vue-router';
import { Form } from 'vee-validate';
import FormButton from '@/components/buttons/formButton.vue';
import { useUserService } from '@/services/userService';
import ReadOnlyText from '@/components/inputs/readOnlyText.vue';
import TextField from '@/components/inputs/textField.vue';
import { UserModel } from '@/models/userModel';
import { formatDate } from '@/date';

export default defineComponent({
  name: 'UpdateProfile',
  components: {
    NavBar,
    FancyBox,
    ReadOnlyText,
    TextField,
    Form,
    FormButton
  },
  setup() {
    const postFailed = ref(false);
    const formValues = ref<UserModel | null>(null);
    const user = ref<UserModel | null>(null);
    // la GET che recupera i valori correnti della company
    onMounted(async () => {
      user.value = await userService.get();
      formValues.value = user.value;
    });
    const userService = useUserService();
    const errorMessage = ref<string | null>(null);
    onErrorCaptured((e: unknown) => {
      errorMessage.value = (e as Error).message;
      return false;
    });

    const router = useRouter();
    async function updateUser(updatedUser: UserModel) {
      console.log(updatedUser);
      try {
        await userService.update(updatedUser);
        // userModel.value = updatedUser;
        router.push({ name: 'userprofile' });
      } catch (e) {
        postFailed.value = true;
        console.log(e);
      }
    }

    return { errorMessage, formValues, postFailed, updateUser, user, formatDate };
  }
});
