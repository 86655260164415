
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GreenButton',
  props: {
    link: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => ({})
    }
  }
});
