
import { defineComponent, onMounted, ref } from 'vue';
import { UserModel } from '@/models/userModel';
import { useInvitationService } from '@/services/invitationService';
import { useRoute, useRouter } from 'vue-router';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form } from 'vee-validate';
import { InvitationModel } from '@/models/invitationModel';

export default defineComponent({
  name: 'RegisterUserFromInvite',
  components: {
    NavBar,
    TextField,
    FormButton,
    Form
  },
  setup() {
    //definiamo di usare i service necessari per gestire la chiamata all'API
    const invService = useInvitationService();
    // definiamo il router da utilizzare per il reindirizzamento dopo la registrazione
    const router = useRouter();
    // definiamo il route da utilizzare per prendere i parametri all'url
    const route = useRoute();
    // prendiamo il valore del parametro code
    const activationCode = route.params.code as string;
    // dichiariamo la variabile invitation
    const invitation = ref<InvitationModel | undefined>(undefined);

    const postFailed = ref(false);

    onMounted(async () => {
      invitation.value = await invService.get(activationCode);
    });

    // Define a register function
    async function register(userModel: UserModel) {
      if (invitation.value) {
        try {
          await invService.createUser(activationCode, userModel);
          router.push({ name: 'login' });
        } catch (e) {
          postFailed.value = true;
        }
      } else {
        invitation.value === undefined;
      }
    }

    return {
      register,
      invitation,
      postFailed
    };
  }
});
