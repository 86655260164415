import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col min-h-screen"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_5 = { class: "max-w-full md:max-w-5xl lg:max-w-md mx-auto pt-12" }
const _hoisted_6 = {
  key: 0,
  class: "mt-6 text-center font-semibold py-6 border-2 border-white rounded-lg bg-gray-100 bg-opacity-50"
}
const _hoisted_7 = { class: "rounded-lg bg-white p-12 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_FancyBox = _resolveComponent("FancyBox")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Form = _resolveComponent("Form")!

  return (!_ctx.company)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_NavBar, {
          pageTitle: _ctx.$t('updateCompanyProfile.manageCompany')
        }, null, 8, ["pageTitle"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.postFailed)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('updateCompanyProfile.error')), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_Form, {
              onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateCompany($event as any))),
              initialValues: { ..._ctx.formValues }
            }, {
              default: _withCtx(({ meta: formMeta, values }) => [
                _createVNode(_component_FancyBox, {
                  listTitle: _ctx.$t('updateCompanyProfile.companyDetails')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_TextField, {
                        name: "company_name",
                        rules: "required",
                        placeholder: _ctx.$t('updateCompanyProfile.buisnessNamePlaceholder')
                      }, null, 8, ["placeholder"]),
                      _createVNode(_component_TextField, {
                        name: "address",
                        rules: "required",
                        placeholder: _ctx.$t('updateCompanyProfile.addressPlaceholder')
                      }, null, 8, ["placeholder"]),
                      _createVNode(_component_TextField, {
                        name: "postal_code",
                        rules: "required",
                        placeholder: _ctx.$t('updateCompanyProfile.postalCodePlaceholder')
                      }, null, 8, ["placeholder"]),
                      _createVNode(_component_TextField, {
                        name: "city",
                        rules: "required",
                        placeholder: _ctx.$t('updateCompanyProfile.cityPlaceholder')
                      }, null, 8, ["placeholder"]),
                      _createVNode(_component_TextField, {
                        name: "state",
                        rules: "required",
                        placeholder: _ctx.$t('updateCompanyProfile.provincePlaceholder')
                      }, null, 8, ["placeholder"]),
                      _createVNode(_component_TextField, {
                        name: "country",
                        rules: "required",
                        placeholder: _ctx.$t('updateCompanyProfile.countryPlaceholder')
                      }, null, 8, ["placeholder"]),
                      _createVNode(_component_TextField, {
                        name: "vat",
                        rules: "required",
                        placeholder: _ctx.$t('updateCompanyProfile.vatNumberPlaceholder')
                      }, null, 8, ["placeholder"]),
                      _withDirectives(_createElementVNode("div", null, [
                        _createVNode(_component_TextField, {
                          name: "fiscal_code",
                          placeholder: _ctx.$t('updateCompanyProfile.fiscalCodePlaceholder')
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_TextField, {
                          name: "sdi_code",
                          placeholder: _ctx.$t('updateCompanyProfile.sdiCodePlaceholder')
                        }, null, 8, ["placeholder"])
                      ], 512), [
                        [_vShow, ['Italia', 'Italy'].includes(values.country)]
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["listTitle"]),
                _createVNode(_component_FormButton, {
                  type: "submit",
                  disabled: !formMeta.valid,
                  float: "center",
                  title: _ctx.$t('updateCompanyProfile.updateData')
                }, null, 8, ["disabled", "title"])
              ]),
              _: 1
            }, 8, ["initialValues"])
          ])
        ])
      ]))
}