import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "transition duration-500 ease-in-out transform hover:scale-110 bg-gray-100 py-4 px-6 rounded-full text-gray-700 font-semibold inline-flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    to: { name: _ctx.link, params: _ctx.params }
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}