import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col min-h-screen"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_5 = { class: "max-w-full md:max-w-7xl lg:max-w-5xl mx-auto mt-6" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-x-6 p-6 md:p-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_ReadOnlyText = _resolveComponent("ReadOnlyText")!
  const _component_FancyBox = _resolveComponent("FancyBox")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Form = _resolveComponent("Form")!

  return (!_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_NavBar, {
          pageTitle: _ctx.$t('updateUserProfile.update')
        }, null, 8, ["pageTitle"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Form, {
              onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateUser($event as any))),
              "initial-values": { ..._ctx.formValues }
            }, {
              default: _withCtx(({ meta: formMeta }) => [
                _createVNode(_component_FancyBox, {
                  listTitle: _ctx.$t('updateUserProfile.personalDetails')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_TextField, {
                        name: "first_name",
                        label: _ctx.$t('updateUserProfile.name')
                      }, null, 8, ["label"]),
                      _createVNode(_component_TextField, {
                        name: "last_name",
                        label: _ctx.$t('updateUserProfile.surname')
                      }, null, 8, ["label"]),
                      _createVNode(_component_ReadOnlyText, {
                        label: _ctx.$t('updateUserProfile.email'),
                        text: _ctx.user.email
                      }, null, 8, ["label", "text"]),
                      _createVNode(_component_ReadOnlyText, {
                        label: _ctx.$t('updateUserProfile.since'),
                        text: _ctx.user.registration_date && _ctx.formatDate(_ctx.user.registration_date)
                      }, null, 8, ["label", "text"])
                    ])
                  ]),
                  _: 1
                }, 8, ["listTitle"]),
                _createVNode(_component_FormButton, {
                  disabled: !formMeta.valid,
                  float: "center",
                  title: _ctx.$t('updateUserProfile.confirm')
                }, null, 8, ["disabled", "title"])
              ]),
              _: 1
            }, 8, ["initial-values"])
          ])
        ])
      ]))
}