import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "transition duration-500 ease-in-out transform hover:scale-110 bg-gray-50 py-4 px-6 rounded-full text-blue-500 font-semibold hover:" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", { 'justify-end': _ctx.float === 'right', 'justify-start': _ctx.float === 'left', 'justify-center': _ctx.float === 'center' }])
  }, [
    _createVNode(_component_RouterLink, {
      to: { name: _ctx.link }
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", _hoisted_1, _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ], 2))
}