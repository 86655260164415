
import { UserModel } from '@/models/userModel';
import { defineComponent, PropType, ref } from 'vue';
import IconButton from '@/components/buttons/iconButton.vue';
import GrayButton from '@/components/buttons/grayButton.vue';
import RedButton from '@/components/buttons/redButton.vue';
import { useUserService } from '@/services/userService';
import { formatDate } from '@/date';

export default defineComponent({
  name: 'UserModal',
  components: {
    IconButton,
    GrayButton,
    RedButton
  },
  props: {
    user: {
      type: Object as PropType<UserModel>,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const isModalOpen = ref(true);

    const deleteOption = ref(false);
    const userService = useUserService();
    async function deleteUser(email: string) {
      try {
        await userService.delete(email);
        isModalOpen.value = false;
        props.close(true);
      } catch (error) {
        console.log(error);
      }
    }

    return {
      deleteOption,
      deleteUser,
      isModalOpen,
      formatDate
    };
  }
});
