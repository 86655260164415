
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LightButton',
  props: {
    float: {
      type: String,
      default: () => 'center'
    },
    link: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      required: true
    }
  }
});
