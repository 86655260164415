
import { defineComponent, onMounted, ref, watch } from 'vue';
import { Chart, PieController, ArcElement, CategoryScale, Legend, Tooltip } from 'chart.js';
import { useProgramService } from '@/services/machineService';
import SimpleCard from '@/components/beautified-containers/simpleCard.vue';
import { ProductivityModel } from '@/models/machineModel';
Chart.register(PieController, ArcElement, CategoryScale, Legend, Tooltip);

export default defineComponent({
  name: 'ProductivityTable',
  components: {
    SimpleCard
  },
  props: {
    loc: {
      type: String,
      required: true
    },
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const programService = useProgramService();
    const productivity = ref<Pick<
      ProductivityModel,
      'daily_w_hours' | 'daily_wash_no_avg' | 'most_run_p' | 'programs_no' | 'working_days' | 'working_hours'
    > | null>(null);
    const programs = ref<
      Array<{
        name: string;
        run: string;
        totHours: string;
        average: string;
      }>
    >([]);

    async function getPrograms() {
      const result = await programService.get(props.loc, props.start, props.end);
      programs.value = [];

      Object.keys(result.programs).forEach(key => {
        console.log(key, result.programs[key]);
        const obj = {
          name: key,
          run: result.programs[key].run.toString(),
          totHours: (result.programs[key].totSec / 3600).toFixed(1),
          average: Math.floor(result.programs[key].avg / 60).toString()
        };
        programs.value.push(obj);
      });

      productivity.value = {
        daily_w_hours: result.daily_w_hours,
        daily_wash_no_avg: result.daily_wash_no_avg,
        most_run_p: result.most_run_p,
        programs_no: result.programs_no,
        working_days: result.working_days,
        working_hours: result.working_hours
      };
    }

    watch([props.start, props.end], () => {
      getPrograms();
    });

    onMounted(() => getPrograms());

    return { productivity, programs };
  }
});
