
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FancyCard',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    }
  }
});
