
import { defineComponent, onErrorCaptured, onMounted, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';
import IconButton from '@/components/buttons/iconButton.vue';
import ReadOnlyText from '@/components/inputs/readOnlyText.vue';
import { CompanyModel, SubscriptionModel } from '@/models/companyModel';
import { CompanyModelWithUsers, useCompanyService } from '@/services/companyService';
import UserAvatar from '@/components/beautified-containers/userAvatar.vue';
import UserModal from '@/components/beautified-containers/userModal.vue';
import { UserModel } from '@/models/userModel';
import { formatDate } from '@/date';

export default defineComponent({
  name: 'CompanyProfile',
  components: {
    NavBar,
    IconButton,
    FancyBox,
    ReadOnlyText,
    UserAvatar,
    UserModal
  },
  setup() {
    const companyService = useCompanyService();
    const company = ref<CompanyModelWithUsers | null>(null);
    const subscription = ref<SubscriptionModel | null>(null);
    const formValues = ref<CompanyModel | null>(null);
    // la GET che recupera i valori correnti della company
    onMounted(async () => {
      company.value = await companyService.get();
      subscription.value = await companyService.getSubscription();
      // Popoliamo i valori iniziali del form con i valori del sistema corrente
      formValues.value = company.value;
    });
    const userModal = ref(false);
    const currentUser = ref<UserModel | null>(null);
    function openUserModal(user: UserModel) {
      userModal.value = true;
      currentUser.value = user;
      console.log(currentUser.value);
    }
    function closeUserModal(reload?: boolean) {
      if (reload) {
        // fetch organization
        companyService.get().then(newCompany => (company.value = newCompany));
      }

      userModal.value = false;
      currentUser.value = null;
    }

    const errorMessage = ref<string | null>(null);
    onErrorCaptured((e: unknown) => {
      errorMessage.value = (e as Error).message;
      return false;
    });

    return { company, subscription, errorMessage, userModal, currentUser, openUserModal, closeUserModal, formatDate };
  }
});
