import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Suspense as _Suspense, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen" }
const _hoisted_2 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_3 = { class: "max-w-full md:max-w-7xl lg:max-w-5xl mx-auto pt-12" }
const _hoisted_4 = {
  key: 0,
  class: "mt-6 mb-2 text-center font-semibold py-6 border-2 border-white rounded-lg bg-gray-100 bg-opacity-50"
}
const _hoisted_5 = { class: "rounded-xl" }
const _hoisted_6 = { class: "py-2 px-4" }
const _hoisted_7 = {
  key: 0,
  class: "mt-6 text-center font-semibold py-6 border-2 border-white rounded-lg bg-gray-100 bg-opacity-50"
}
const _hoisted_8 = { class: "flex space-x-4 justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_FancyBox = _resolveComponent("FancyBox")!
  const _component_SelectUsers = _resolveComponent("SelectUsers")!
  const _component_GrayButton = _resolveComponent("GrayButton")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, {
      pageTitle: _ctx.$t('createLocation.add')
    }, null, 8, ["pageTitle"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.postFailed)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('createLocation.error')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Form, {
            onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createLocation($event as any)))
          }, {
            default: _withCtx(({ meta: formMeta, setFieldValue }) => [
              _createVNode(_component_FancyBox, {
                listTitle: _ctx.$t('createLocation.info')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_TextField, {
                      name: "location_name",
                      placeholder: _ctx.$t('createLocation.name')
                    }, null, 8, ["placeholder"]),
                    _createVNode(_component_TextArea, {
                      rows: 5,
                      name: "address",
                      placeholder: _ctx.$t('createLocation.address')
                    }, null, 8, ["placeholder"])
                  ])
                ]),
                _: 1
              }, 8, ["listTitle"]),
              (_ctx.errorMessage)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('createLocation.error2')) + _toDisplayString(_ctx.errorMessage), 1))
                : (_openBlock(), _createBlock(_Suspense, { key: 1 }, {
                    fallback: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('createLocation.loading')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_SelectUsers, {
                        name: "users",
                        rules: "required",
                        getExistingUsers: () => [],
                        onUsersHaveBeenSelected: 
                  selectedUsers =>
                    setFieldValue(
                      'users',
                      selectedUsers.map((user) => user.email)
                    )
                
                      }, null, 8, ["getExistingUsers", "onUsersHaveBeenSelected"])
                    ]),
                    _: 2
                  }, 1024)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_GrayButton, {
                  title: _ctx.$t('createLocation.cancel'),
                  link: "locations"
                }, null, 8, ["title"]),
                _createVNode(_component_FormButton, {
                  disabled: !formMeta.valid,
                  float: "center",
                  title: _ctx.$t('createLocation.confirm')
                }, null, 8, ["disabled", "title"])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}