
import { defineComponent } from 'vue';
import { ErrorMessage, Field } from 'vee-validate';

export default defineComponent({
  name: 'TextField',
  components: {
    ErrorMessage,
    Field
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: () => 'text'
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: () => false
    }
  }
});
