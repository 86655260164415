
import { defineComponent, ref } from 'vue';
import { UserModel } from '@/models/userModel';
import { useUserService } from '@/services/userService';
import { useRoute, useRouter } from 'vue-router';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form } from 'vee-validate';
import '@/forms';

export default defineComponent({
  name: 'RegisterUser',
  components: {
    NavBar,
    TextField,
    FormButton,
    Form
  },
  setup() {
    //definiamo di usare lo userService per gestire la chiamata all'API e successivamente salvare lo stato della risposta
    const userService = useUserService();

    //variabile per mostrare esito
    const registrationFailed = ref(false);

    // definiamo il router da utilizzare per il reindirizzamento dopo la registrazione
    const router = useRouter();

    // definiamo il route da utilizzare per prendere i parametri all'url
    const route = useRoute();
    // prendiamo il valore del parametro code
    const product = route.params.product as string;
    const recurring = route.params.recurring as string;

    // Define a register function
    async function register(userModel: UserModel & { password: string }) {
      console.log(userModel);
      try {
        await userService.registerAdmin(userModel);
        router.push({ name: 'registerOrganization', params: { product: product, recurring: recurring } });
      } catch (e) {
        console.log(e);
        registrationFailed.value = true;
      }
    }

    return {
      register,
      registrationFailed
    };
  }
});
