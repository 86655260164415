import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded-xl" }
const _hoisted_2 = {
  key: 0,
  class: "bg-gradient-to-br from-gray-100 to-gray-300 py-4 rounded-t-2xl px-16"
}
const _hoisted_3 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_4 = { class: "text-xl font-semibold text-left" }
const _hoisted_5 = {
  key: 1,
  class: "bg-gradient-to-br from-gray-100 to-gray-300 h-1 rounded-t-2xl px-16"
}
const _hoisted_6 = { class: "bg-white bg-opacity-100 rounded-b-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.listTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.listTitle), 1),
            _renderSlot(_ctx.$slots, "iconbutton")
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5)),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}