
import { defineComponent, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form, SubmissionContext } from 'vee-validate';
import '@/forms';
import { useInvitationService } from '@/services/invitationService';
import { InvitationModel } from '@/models/invitationModel';
import ModalMessage from '@/components/beautified-containers/modalMessage.vue';
import GreenButton from '@/components/buttons/greenButton.vue';
// import GrayButton from '@/components/buttons/grayButton.vue';
import { useUserService } from '@/services/userService';
export default defineComponent({
  name: 'CreateInvitation',
  components: {
    NavBar,
    TextField,
    FormButton,
    Form,
    ModalMessage,
    GreenButton
  },
  setup() {
    const { userModel } = useUserService();
    //definiamo di usare lo userService per gestire la chiamata all'API e successivamente salvare lo stato della risposta
    const invitationService = useInvitationService();
    const postFailed = ref(false);

    //definiamo variabile boolean showModal
    const showModal = ref(false);

    // Define a register function
    async function invite(invitationModel: InvitationModel, actions: SubmissionContext<Record<string, any>>) {
      try {
        await invitationService.create(invitationModel);
        actions.resetForm();
        showModal.value = true;
      } catch (e) {
        postFailed.value = true;
        console.log(e);
      }
    }
    return {
      invite,
      postFailed,
      showModal,
      userModel
    };
  }
});
