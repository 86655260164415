
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormButton',
  props: {
    float: {
      type: String,
      default: () => 'center'
    },
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  }
});
