import axios from 'axios';
import { MachineModel, LogModel, ConsumptionModel, ErrorLogModel, ProductivityModel } from '@/models/machineModel';
import { serverUrl } from './constants';
import { ISODateTimeToISODate } from '@/date';

export function useMachineService() {
  return {
    async list(locationName: string): Promise<Array<MachineModel>> {
      const resposne = await axios.get<Array<MachineModel>>(serverUrl + '/' + locationName + '/machines');
      return resposne.data;
    },

    async get(locationName: string, machineName: string): Promise<MachineModel> {
      const response = await axios.get<MachineModel>(serverUrl + '/' + locationName + '/machines/' + machineName);
      return response.data;
    },

    async create(locationName: string, machineName: string): Promise<any> {
      const response = await axios.post<MachineModel>(serverUrl + '/' + locationName + '/machines/create', { name: machineName });
      return response.data;
    },

    async update(machineName: string, newMachineName: string): Promise<MachineModel> {
      const response = await axios.put(serverUrl + '/machines/' + machineName + '/edit', { name: newMachineName });
      return response.data;
    },

    async delete(machineName: string): Promise<void> {
      await axios.delete(serverUrl + '/machines/' + machineName);
    }
  };
}

export function useLogService() {
  return {
    async get(loc: string, start_date: string, end_date: string, send_logs: string): Promise<LogModel> {
      const response = await axios.get<LogModel>(
        serverUrl + `/logs/?loc=${loc}&start_date=${start_date}&end_date=${end_date}&send_logs=${send_logs}/`
      );
      return response.data;
    },

    async create(newLog: LogModel): Promise<LogModel> {
      const response = await axios.post<LogModel>(serverUrl + `/logs/`, newLog);
      return response.data;
    },
    //Verificare, forse non corretta (occorre passare tutti i nuovi parametri?)
    async update(logId: string, updatedLog: LogModel): Promise<LogModel> {
      const response = await axios.put(serverUrl + `/logs/${logId}/`, updatedLog);
      return response.data;
    },

    async delete(logId: number): Promise<void> {
      await axios.delete(serverUrl + `/logs/${logId}/`);
    }
  };
}

export function useConsService() {
  return {
    async get(loc: string, start_date: string, end_date: string, send_logs: string): Promise<ConsumptionModel> {
      start_date = ISODateTimeToISODate(start_date);
      end_date = ISODateTimeToISODate(end_date);
      const response = await axios.get<ConsumptionModel>(
        serverUrl + `/consumptions/?loc=${loc}&start_date=${start_date}&end_date=${end_date}&send_logs=${send_logs}`
      );
      return response.data;
    }
  };
}

export function useProgramService() {
  return {
    async get(loc: string, start_date: string, end_date: string): Promise<ProductivityModel> {
      start_date = ISODateTimeToISODate(start_date);
      end_date = ISODateTimeToISODate(end_date);
      const response = await axios.get<ProductivityModel>(
        serverUrl + `/programs/?loc=${loc}&start_date=${start_date}&end_date=${end_date}`
      );
      return response.data;
    }
  };
}

export function useErrorLogService() {
  return {
    async get(loc: string, start_date: string, end_date: string): Promise<ErrorLogModel> {
      start_date = ISODateTimeToISODate(start_date);
      end_date = ISODateTimeToISODate(end_date);
      const response = await axios.get<ErrorLogModel>(serverUrl + `/errors/?loc=${loc}&start_date=${start_date}&end_date=${end_date}`);
      return response.data;
    }
  };
}
