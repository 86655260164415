import { defineRule, configure } from 'vee-validate';
import { required, email, min, confirmed } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('email', email);
defineRule('min', min);
configure({
  validateOnInput: true,
  generateMessage: localize({
    en: {
      messages: {
        required: `This field is required.`,
        min: `This field must be at least 0:{min} characters`,
        email: 'Please enter a valid email',
        // confirmed: context => `The ${context.field} entered does not match.`,
        confirmed: 'The passwords entered do not match'
      }
    },
    it: {
      messages: {
        required: `Per favore compila questo campo`,
        min: `Inserisci almeno 0:{min} caratteri`,
        email: 'Inserisci un indirizzo e-mail valido',
        // confirmed: context => `La ${context.field} inserita non corrisponde alla precedente`,
        confirmed: 'Le password inserite non corrispondono'
      }
    }
  })
});
//TODO check
