import { createApp } from 'vue';
import App from './App.vue';
import './style/index.css';
import router from './router';
// import  from './i18n'
import { i18n } from './i18n';
import { createPinia } from 'pinia';

const pinia = createPinia();

createApp(App).use(router).use(i18n).use(pinia).mount('#app');
