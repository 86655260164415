
import { defineComponent, onErrorCaptured, onMounted, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';
import IconButton from '@/components/buttons/iconButton.vue';
import { useUserService } from '@/services/userService';
import ReadOnlyText from '@/components/inputs/readOnlyText.vue';
import { SUPPORT_LOCALES } from '@/i18n';
import { DATE_FORMATS, formatDate } from '@/date';
import { UserModel } from '@/models/userModel';

export default defineComponent({
  name: 'UserProfile',
  components: {
    NavBar,
    IconButton,
    FancyBox,
    ReadOnlyText
  },
  setup() {
    const userService = useUserService();
    const user = ref<UserModel>();
    // const userId = route.params.userId as string;
    // la GET che recupera i valori correnti della company
    onMounted(async () => {
      user.value = await userService.get();
    });
    const errorMessage = ref<string | null>(null);
    onErrorCaptured((e: unknown) => {
      errorMessage.value = (e as Error).message;
      return false;
    });

    const showLangDropdown = ref(false);
    const toggleLangDropdown = () => (showLangDropdown.value = !showLangDropdown.value);

    const showDateFormatDropdown = ref(false);
    const toggleDateFormatDropdown = () => (showDateFormatDropdown.value = !showDateFormatDropdown.value);

    async function selectLocale(locale: string) {
      showLangDropdown.value = false;

      //post update
      user.value!.preferred_language = locale;
      user.value = await userService.update(user.value!);
    }
    async function selectDateFormat(dateFormat: string) {
      showDateFormatDropdown.value = false;

      const index: number = DATE_FORMATS.indexOf(dateFormat);

      //post update
      user.value!.date_format = index;
      user.value = await userService.update(user.value!);
    }

    return {
      user,
      errorMessage,
      showLangDropdown,
      toggleLangDropdown,
      SUPPORT_LOCALES,
      selectLocale,
      DATE_FORMATS,
      showDateFormatDropdown,
      toggleDateFormatDropdown,
      selectDateFormat,
      formatDate
    };
  }
});
