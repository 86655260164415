import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col min-h-screen"
}
const _hoisted_3 = { class: "bg-gradient-to-tr from-blue-500 to-green-500 bg-opacity-100 h-full flex-grow" }
const _hoisted_4 = { class: "max-w-full md:max-w-7xl lg:max-w-5xl mx-auto pt-12" }
const _hoisted_5 = {
  key: 0,
  class: "mt-6 mb-2 text-center font-semibold py-6 border-2 border-white rounded-lg bg-gray-100 bg-opacity-50"
}
const _hoisted_6 = { class: "rounded-xl" }
const _hoisted_7 = { class: "py-2 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_FancyBox = _resolveComponent("FancyBox")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Form = _resolveComponent("Form")!

  return (!_ctx.currentLocation)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_NavBar, {
          pageTitle: _ctx.$t('updateLocation.updateData')
        }, null, 8, ["pageTitle"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.postFailed)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('updateLocation.error')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Form, {
                onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateLocation($event as any))),
                "initial-values": { ..._ctx.formValues }
              }, {
                default: _withCtx(({ meta: formMeta }) => [
                  _createVNode(_component_FancyBox, {
                    listTitle: _ctx.$t('updateLocation.baseInfo')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_TextField, {
                          name: "location_name",
                          placeholder: _ctx.$t('updateLocation.name')
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_TextArea, {
                          rows: 3,
                          name: "address",
                          placeholder: _ctx.$t('updateLocation.address')
                        }, null, 8, ["placeholder"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["listTitle"]),
                  _createVNode(_component_FormButton, {
                    disabled: !formMeta.valid,
                    float: "center",
                    title: _ctx.$t('updateLocation.confirm')
                  }, null, 8, ["disabled", "title"])
                ]),
                _: 1
              }, 8, ["initial-values"])
            ])
          ])
        ])
      ]))
}