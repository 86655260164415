
import { defineComponent } from 'vue';
import NavBar from '@/components/header/navbar.vue';

export default defineComponent({
  name: 'RequestPasswordResetSent',
  components: {
    NavBar
  }
});
