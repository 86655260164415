
import { defineComponent, onMounted, ref } from 'vue';
import { CompanyModel } from '@/models/companyModel';
import { useCompanyService } from '@/services/companyService';
import { useUserService } from '@/services/userService';
import { usePlanService } from '@/services/planService';
import { useRoute } from 'vue-router';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import FormButton from '@/components/buttons/formButton.vue';
import GrayButton from '@/components/buttons/grayButton.vue';
import LightButton from '@/components/buttons/lightButton.vue';
import GenericModal from '@/components/beautified-containers/genericModal.vue';
import { Form } from 'vee-validate';
import '@/forms';
import { PlanModel } from '@/models/planModel';

export default defineComponent({
  name: 'RegisterOrganization',
  components: {
    NavBar,
    TextField,
    FormButton,
    Form,
    GenericModal,
    LightButton,
    GrayButton
  },
  setup() {
    //definiamo di usare il service per gestire la chiamata all'API e successivamente salvare lo stato della risposta
    const companyService = useCompanyService();
    const planService = usePlanService();
    const { userModel } = useUserService();
    const userService = useUserService();

    // definiamo il router da utilizzare per il reindirizzamento dopo la registrazione
    const route = useRoute();
    // prendiamo il valore del parametro code
    const product = ref(route.params.product as string);
    const recurring = ref(route.params.recurring as string);

    //definiamo variabile popup scleta piano
    const modalOpen = ref(false);

    // Rintracciamo i piani disponibili da API
    const plans = ref<Array<PlanModel>>([]);
    onMounted(async () => {
      // Recuperiamo i valori di plans dall'API
      plans.value = await planService.get(product.value, recurring.value);
      // Controlliamo che gli argomenti presenti nell'url siano piani validi
      let productFound = false;
      let rateFound = false;
      if (product.value !== null && recurring.value) {
        for (let p = 0; p < plans.value.length; p++) {
          let plan = plans.value[p];
          if (plan.key === product.value) {
            productFound = true;
            for (let r = 0; r < plan.rates.length; r++) {
              if (plan.rates[r].key === recurring.value) {
                rateFound = true;
              }
            }
          }
        }
      }
      if (productFound && rateFound) {
        savePlanSelection();
      } else {
        product.value = '';
        recurring.value = '';
      }
    });
    // Gestiamo la selezione dell'importo ricorrente
    function rateSelect(planKey: string, rateKey: string) {
      plans.value.map(plan => {
        if (plan.key === planKey) {
          let index = 0;
          plan.rates.map(rate => {
            rate.selected = rateKey === rate.key;
            if (rate.selected) {
              plan.selected_rate_index = index;
            }
            index++;
            return rate;
          });
        }
        return plan;
      });
    }
    // Gestiamo la selezione del piano
    const planSelect = (planKey: string) =>
      plans.value.map(plan => {
        plan.selected = planKey === plan.key;
        // product.value = plan.name;
        return plan;
      });
    // Salviamo piano e ricorrenza scelti
    function savePlanSelection() {
      for (let i = 0; i < plans.value.length; i++) {
        let plan: PlanModel = plans.value[i];
        console.log(plan.name, plan.selected);
        if (plan.selected) {
          product.value = plan.key;
          recurring.value = plan.rates[plan.selected_rate_index].key;
        }
      }
    }

    // Definiamo una funzione per la registrazione dell'azienda
    async function registerCom(companyModel: CompanyModel) {
      try {
        // Creiamo la nuova azienda
        await companyService.create(companyModel);

        // Se lo stato dello userModel è diverso da null, allora aggiorniamo il field organization
        // Il controllo dell'esistenza dello stato è obbligatorio per typescript
        if (userModel.value !== null) {
          // userModel.value.organization = company;
          try {
            // userService.update(userModel.value.id as unknown as string, userModel.value);
            // Richiamiamo il nuovo modello salvato che contiene l'aggiornamento effettuato
            userService.userModel;
          } catch (error) {
            console.log("Ecco l'errore " + error);
          }
        }

        // get
        console.log(product.value, recurring.value);

        const checkout_url = await companyService.checkout(product.value, recurring.value);
        console.log(checkout_url);
        // Reindirizziamo al checkout della subscription
        window.location.href = checkout_url;
        // router.push({ name: 'locations' });
      } catch (e) {
        // registrationFailed.value = true
        console.log('Errore nel primo try ' + e);
      }
    }

    function getCostAndBilled(planKey: string, rateSelectedRateIndex: number) {
      const plan = plans.value.find(p => p.key == planKey);
      const cost = plan!.rates[rateSelectedRateIndex].price_in_cent / 100;
      const recurring = plan!.rates[plan!.selected_rate_index].recurring_in_month;
      return { cost: cost, recurring: recurring };
    }

    return {
      registerCom,
      product,
      recurring,
      modalOpen,
      plans,
      rateSelect,
      planSelect,
      savePlanSelection,
      getCostAndBilled
    };
  }
});
