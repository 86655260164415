
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SearchBar',
  props: {
    placeholder: {
      type: String,
      default: () => undefined
    }
  }
});
