import axios from 'axios';
import { InvitationModel } from '@/models/invitationModel';
import { serverUrl } from './constants';
import { UserModel } from '@/models/userModel';

export function useInvitationService() {
  return {
    async get(invitationCode: string): Promise<any> {
      const response = await axios.get(serverUrl + '/invitations/' + invitationCode);
      return response.data;
    },

    async create(newInvitation: InvitationModel): Promise<any> {
      const response = await axios.post(serverUrl + '/invitations', newInvitation);
      return response.data;
    },

    async createUser(invitationCode: string, user: UserModel): Promise<any> {
      const response = await axios.put(serverUrl + '/invitations/' + invitationCode, user);
      return response.data;
    },

    async delete(invitationCode: string): Promise<void> {
      await axios.delete(serverUrl + '/invitations/' + invitationCode);
    }
  };
}
