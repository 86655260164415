
import { defineComponent } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import GreenButton from '@/components/buttons/greenButton.vue';

export default defineComponent({
  name: 'NewPasswordSent',
  components: {
    NavBar,
    GreenButton
  }
});
