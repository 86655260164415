import { todayDate } from '@/date';
import { defineStore } from 'pinia';

export const useDatepickerStore = defineStore('datepicker', {
  state: () => {
    const yesterday = todayDate('-1day');
    const sixmonthsago = todayDate('-12months');
    return {
      _yesterday: yesterday,
      _sixmonthsago: sixmonthsago,
      start_date: sixmonthsago,
      end_date: yesterday
    };
  },
  getters: {
    yesterday: state => state._yesterday,
    sixmonthsago: state => state._sixmonthsago
  }
  // actions: {
  //   increment() {
  //     this.count++
  //   },
  // },
});
