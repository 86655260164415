import dayjs, { ManipulateType } from 'dayjs';

export const DATE_FORMATS = ['YYYY/MM/DD', 'DD/MM/YYYY', 'MM/DD/YYYY'];
let dateFormat = 0;

export function setPreferredDateFormat(preferredFormat: number | undefined) {
  if (preferredFormat) {
    dateFormat = preferredFormat;
  } else {
    dateFormat = 0;
  }
}

export function todayDateTime(transform?: string): string {
  let today = dayjs();

  // make date transformation if required
  if (transform) {
    const re = transform.match(/(?<symbol>[+-])(?<value>\d*)(?<unit>millisecond|second|minute|hour|day|month|year)/);
    if (re?.groups) {
      const value: number = parseInt(re.groups.value);
      const unit: ManipulateType = re.groups.unit as ManipulateType;
      const symbol: string = re.groups.symbol;
      if (symbol == '+') today = today.add(value, unit);
      else today = today.subtract(value, unit);
    }
  }
  // format('YYYY-MM-DD');
  return today.toISOString();
}

export function ISODateTimeToISODate(ISODateTime: string): string {
  const re = ISODateTime.match(/(?<date>\d*-\d*-\d*)/); //T(?<time>\d*:\d*:\d*)\.(.*)/); //2022-03-09T15:03:34.010Z
  if (re?.groups) {
    return re.groups.date;
  }
  return ISODateTime;
}

export function todayDate(transform?: string): string {
  return ISODateTimeToISODate(todayDateTime(transform));
}

export function formatDate(date: string): string {
  const re = date.match(/(?<y>\d*)-(?<m>\d*)-(?<d>\d*)/);
  if (re?.groups) {
    return DATE_FORMATS[dateFormat].replace('YYYY', re.groups.y).replace('MM', re.groups.m).replace('DD', re.groups.d);
  }
  return date;
}
