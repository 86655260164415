
import { defineComponent, onErrorCaptured, ref } from 'vue';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import TextArea from '@/components/inputs/textArea.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form } from 'vee-validate';
import SelectUsers from '@/components/inputs/selectUsers.vue';
import { LocationModel } from '@/models/locationModel';
import { useLocationService } from '@/services/locationService';
import { useRouter } from 'vue-router';
import FancyBox from '@/components/beautified-containers/fancyBox.vue';
import GrayButton from '@/components/buttons/grayButton.vue';

export default defineComponent({
  name: 'CreateSystem',
  components: {
    NavBar,
    FancyBox,
    TextField,
    TextArea,
    FormButton,
    Form,
    SelectUsers,
    GrayButton
  },
  setup() {
    const locationService = useLocationService();
    const router = useRouter();
    const postFailed = ref(false);
    async function createLocation(newLoc: LocationModel) {
      console.log(newLoc);
      try {
        const response = await locationService.create(newLoc);
        router.push({ name: 'location', params: { locationName: response.location_name } });
      } catch (e) {
        postFailed.value = true;
        console.log(e);
      }
    }
    const errorMessage = ref<string | null>(null);
    onErrorCaptured((e: unknown) => {
      errorMessage.value = (e as Error).message;
      return false;
    });
    return {
      errorMessage,
      createLocation,
      postFailed
    };
  }
});
