
import { defineComponent, ref } from 'vue';
import { useUserService } from '@/services/userService';
import { useRoute, useRouter } from 'vue-router';
import NavBar from '@/components/header/navbar.vue';
import TextField from '@/components/inputs/textField.vue';
import FormButton from '@/components/buttons/formButton.vue';
import { Form } from 'vee-validate';
import '@/forms';

export default defineComponent({
  name: 'SetNewPassword',
  components: {
    NavBar,
    TextField,
    FormButton,
    Form
  },
  setup() {
    const userService = useUserService();
    const router = useRouter();
    const route = useRoute();
    const token = route.params.token as string;
    const resetFailed = ref(false);
    async function sendNewPassword(credentials: { password: string; token: string }) {
      resetFailed.value = false;
      try {
        credentials.token = token;
        console.log(credentials);
        await userService.sendNewPassword(credentials);
        router.push({ name: 'newPasswordSent' });
      } catch (e) {
        resetFailed.value = true;
      }
    }
    return { sendNewPassword, resetFailed };
  }
});
