
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FancyBox',
  props: {
    listTitle: {
      type: String,
      default: () => ''
    }
  }
});
